import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";
import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  const iconColors = {
    youtube: "red",
    telegram: "#0088CC", // Use hex code for telegram blue
    twitter: "#1DA1F2", // Use hex code for twitter blue
  };

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        p: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "Black",
      }}
    >
      <Box sx={{ display: "flex", gap: 0, fontSize: "28px", fontWeight:500,  }}>
        <IconButton size="large" sx={{  color: iconColors.youtube }}>
          <YouTubeIcon sx={{ height: "50px"  }} />
        </IconButton>
        <p>YouTube</p>
        <IconButton size="large" sx={{ color: iconColors.telegram }}>
          <TelegramIcon />
        </IconButton>
        <p>Telegram</p>
        <IconButton size="large" sx={{ color: iconColors.twitter }}>
          <TwitterIcon />
        </IconButton>
        <p>Twitter</p>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center",  }}>
        <Typography variant="body2" fontSize= "26px">
          © {new Date().getFullYear()} Сделано с ❤️
          <Link href="https://t.me/usernadare"> Парса Абангах</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
