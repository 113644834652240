import React, { useState } from "react";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleTopicsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTopicsClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#fff" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left-aligned "Learn by map" text */}
        <Button color="inherit" sx={{ color: "black" }} component={Link} to="/">
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, mt: 3, fontWeight: 550 }}
          >
            LEARN BY MAP
          </Typography>
        </Button>

        {/* Centered "Темы" button with menu */}
        <Button
          color="inherit"
          sx={{
            fontweight: 400,
            fontSize: 28,
            mt: 3,
            marginLeft: "10px", // Add margin for spacing
            boxShadow: "0px 2px 4px rgba(0, 0, 0, .4)", // Add a subtle shadow
            color: "rgba(0, 0, 0, 0.7)", // Slightly transparent black
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Darker background on hover
            transformOrigin: "0 0 0",
            border: "none", // Remove border
            borderRadius: 5, // Set border radius

            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.2)", // Darken background further on hover
              "& .MuiButton-startIcon": {
                marginRight: "10px", // Adjust icon spacing
              },
              "& .MuiButton-label": {
                paddingRight: "10px", // Adjust text spacing
              },
            },
          }}
          onClick={handleTopicsOpen}
        >
          Темы
          <IconButton edge="end" size="small">
            <KeyboardArrowDownIcon sx={{ color: "rgba(0, 0, 0, 0.7)" }} />
          </IconButton>
        </Button>

        {/* Improved Menu styling */}
        <Menu
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "rgba(5, 213, 119, 0.9)", // Slightly transparent background
              border: "none", // Remove border
              borderRadius: 5, // Set border radius
              // Ensure fixed width is maintained (optional)
              fontSize: "25",
              
            },
          }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleTopicsClose}
        >
          <MenuItem onClick={handleTopicsClose} component={Link} to="/english">
            Английский язык
          </MenuItem>
          <MenuItem sx={{ color: "gray" }} onClick={handleTopicsClose}>
            Математика(В разработке)
          </MenuItem>
          <MenuItem sx={{ color: "gray" }} onClick={handleTopicsClose}>
            Физика(В разработке)
          </MenuItem>
          <MenuItem sx={{ color: "gray" }} onClick={handleTopicsClose}>
            Химия(В разработке)
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
