import React from "react";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import MainBody from "./MainBody";
import EnglishRoadmap from "./EnglishRoadmap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from './Contact';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<MainBody />} />
        <Route path="/english" element={<EnglishRoadmap />} />
        <Route path="/Contact" element={<Contact/>} />
      </Routes>
      <Footer />
      {/* Rest of your app content */}
    </BrowserRouter>
  );
}

export default App;
