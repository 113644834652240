import React, { useState } from "react";
import { Select, FormControl, InputLabel, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Roadmap from "./Roadmap";

interface EnglishRoadmapProps {
  // Определите здесь необходимые свойства
}

const EnglishRoadmap: React.FC<EnglishRoadmapProps> = () => {
  const [level, setLevel] = useState<string>("");
  const [fieldOfStudy, setFieldOfStudy] = useState<string>("");

  const handleLevelChange = (event: SelectChangeEvent<string>) => {
    setLevel(event.target.value);
  };

  const handleFieldOfStudyChange = (event: SelectChangeEvent<string>) => {
    setFieldOfStudy(event.target.value);
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        color: "Black",
        padding: "50px",
        textAlign: "center",
      }}
    >
      <Typography variant="h2" sx={{ mt: 5, fontWeight: 900 }}>
        Освойте английский язык
      </Typography>
      <Typography
        variant="h4"
        sx={{ color: "#05d577", fontWeight: 600, mt: 2 }}
        textAlign="center"
        marginBottom={10}
      >
        💡Пошаговое руководство по тому, как стать носителем английского языка в
        2024 году.
      </Typography>

      <FormControl
        sx={{
          width: "400px",
          mr: 3,
          boxShadow: "2px 4px 15px -3px rgba(0,255,0,1)", // Add box shadow
        }}
      >
        <InputLabel
          id="level-label"
          sx={{ color: "Black", fontSize: "26", fontWeight: 700 }}
        >
          Выберите свой уровень (в разработке🚧)
        </InputLabel>

        <Select
          labelId="level-label"
          id="level-select"
          value={level}
          onChange={handleLevelChange}
          label="Уровень"
          sx={{
            "& .MuiSelect-root": {
              color: "Black",
              borderColor: "green",
              "&:hover": {
                color: "green", // Цвет шрифта при наведении
              },
            },
          }}
        >
          <option value="">-- Выберите уровень --</option>

          <option value="a1">A1: Начальный (Beginner)</option>
          <option value="a2">A2: Элементарный (Elementary)</option>

          <option value="b1">B1: Промежуточный (Intermediate)</option>
          <option value="b2">B2: Выше среднего (Upper Intermediate)</option>

          <option value="c1">C1: Продвинутый (Advanced)</option>
          <option value="c2">
            C2: Профессиональное владение (Proficiency)
          </option>
        </Select>
      </FormControl>

      <FormControl
        sx={{
          width: "400px",
          boxShadow: "2px 4px 15px -3px rgba(0,255,0,1)", // Add box shadow
          ml: 3,
        }}
      >
        <InputLabel
          id="field-of-study-label"
          sx={{ color: "Black", fontWeight: 700 }}
        >
          Выберите область изучения (в разработке🚧)
        </InputLabel>
        <Select
          labelId="field-of-study-label"
          id="field-of-study-select"
          value={fieldOfStudy}
          onChange={handleFieldOfStudyChange}
          label="Область изучения"
          sx={{
            "& .MuiSelect-root": {
              color: "Black",
              borderColor: "green",
              "&:hover": {
                color: "green", // Цвет шрифта при наведении
              },
            },
          }}
        >
          <option value="">-- Выберите уровень --</option>
          <option value="reading">Чтение</option>
          <option value="writing">Письмо</option>
          <option value="grammar">Грамматика</option>
          <option value="pronunciation">Произношение</option>
          <option value="vocabulary">Словарный запас</option>
          <option value="phrasalverbs">Фразовые глаголы</option>
          <option value="businessenglish">Деловой английский</option>
          <option value="academicenglish">Академический английский</option>
          <option value="conversationalenglish">Разговорный английский</option>
          <option value="ielts">Подготовка к IELTS</option>
          <option value="toefl">Подготовка к TOEFL</option>
          <option value="listening">Аудирование</option>
          <option value="speaking">Говорение</option>
          <option value="criticalthinking">Критическое мышление</option>
          <option value="notetaking">Конспектирование</option>
          <option value="essaywriting">Написание эссе</option>
          <option value="research">Исследования</option>
        </Select>
      </FormControl>

      <Roadmap />
    </div>
  );
};

export default EnglishRoadmap;
