import React from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import parsaImage from "./Parsa.jpg";
import { Grid, Avatar } from "@mui/material";

const Contact = () => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={{
        minHeight: "78vh", // Ensure full viewport height
        backgroundColor: "#fff",
        color: "Black",
        overflow: "hidden", // Prevent content from overflowing container
      }}
    >
      <Typography variant="h2" sx={{ mt: 5, fontWeight: 900 }}>
        Давайте свяжемся!
      </Typography>
      <Grid item xs={4}>
        <Avatar
          alt="Parsa Abangah"
          src={parsaImage}
          sx={{ width: 100, height: 100, mt: 2 }}
        />
      </Grid>
      {/* Личная информация */}
      <Stack direction="column" spacing={1}>
        <Typography
          variant="h5"
          align="center"
          sx={{ color: "#05d577", fontWeight: 900, mt: 2 }}
        >
          Парса Абангах (Основатель)
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <IconButton
            href="https://www.linkedin.com/in/parsa-abangah"
            target="_blank"
          >
            <LinkedInIcon sx={{ color: "#05d577" }} />
          </IconButton>
          <IconButton href="https://t.me/usernadare" target="_blank">
            <TelegramIcon sx={{ color: "#05d577" }} />
          </IconButton>
          <IconButton href="https://alvo.chat/3Qr9" target="_blank">
            <WhatsAppIcon sx={{ color: "#05d577" }} />
          </IconButton>
        </Stack>
      </Stack>

      {/* Контактная информация */}
      <Stack direction="column" spacing={1}>
        <Typography variant="h5" sx={{ mt: 5, fontWeight: 700 }}>
          Не стесняйтесь обращаться ко мне, используя любой из вариантов ниже:
        </Typography>
        <Stack direction="row" spacing={5} justifyContent="center">
          <Button
            sx={{ bgcolor: "	#21753d" }}
            variant="contained"
            href="mailto:p22abangah@gmail.com"
            startIcon={<EmailIcon />}
          >
            Письмо
          </Button>
          <Button
            sx={{ bgcolor: "	#21753d" }}
            variant="contained"
            href="tel:+79967880138"
            startIcon={<CallIcon />}
          >
            Звонить
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Contact;
