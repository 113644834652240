import * as React from "react";
import { Grid, Typography, Box } from "@mui/material";
import homepageImage from "./MockupMac.png";
import FlexBox from "./FlexBox.png";
const MainBody: React.FC = () => {
  return (
    <Grid
      component="div"
      spacing={0}
      alignItems="center"
      sx={{ background: "#fff", color: "Black" }}
    >
           
      <Grid item xs={12}>
        <Typography
          variant="h1"
          textAlign="center"
          sx={{ mt: 15, fontWeight: 900 }}
        >
          Путь изучения любого предмета🔥
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: "#05d577", fontWeight: 600, mt: 2 }}
          textAlign="center"
        >
          От начала до конца!
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          height={"80%"}
          draggable="false"
          width={"80%"}
          src={homepageImage}
          alt="Главное изображение"
          style={{ margin: "50px", alignContent: "center" }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {/* <img
          src={FlexBox}
          draggable="false"
          alt="Главное изображение"
          style={{ margin: "50px", alignContent: "center" }}
        />  */}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          textAlign="center"
          sx={{ mt: 10, p: 4, fontWeight: 500 }}
        >
          Это так{" "}
          <b>
            <span style={{ color: "#05d577" }}>просто</span>
          </b>
          🙂. выберите предмет, 👀посмотрите, что нужно{" "}
          <b>
            <span style={{ color: "#f0f424" }}>выучить</span>
          </b>
          , 🚶🏼завершите этапы его изучения. Поздравляю🎉 вы его{" "}
          <b>
            <span style={{ color: "#A191DE" }}>выучили</span>
          </b>
          !
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          textAlign="center"
          sx={{ mt: 20, fontWeight: 900 }}
        >
          Наша платформа развивается...🚀
        </Typography>
        <Typography
          variant="h5"
          sx={{ color: "#05d577", fontWeight: 600, mb: 20 }}
          textAlign="center"
        >
          Текущая версия: 1.0
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MainBody;
