import React, { useCallback } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  BackgroundVariant,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";

const initialNodes = [
  // Существительные:
  {
    id: "Partsofspeech",
    position: { x: -0, y: -100 },
    data: { label: "Введение в грамматику английского языка" },
    style: {
      backgroundColor: "rgb(161, 145, 222)",
      fontWeight: "bold",
      padding: "10px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Nouns",
    position: { x: 0, y: 0 },
    data: { label: "Существительные" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  // Общие
  {
    id: "Nouns-common",
    position: { x: -200, y: 0 },
    data: { label: " Общие " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  // Собственные
  {
    id: "Nouns-proper",
    position: { x: -200, y: 50 },
    data: { label: " Собственные" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  // Единственного числа
  {
    id: "Nouns-singular",
    position: { x: -200, y: 100 },
    data: { label: " Единственного числа " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  // Множественного числа
  {
    id: "Nouns-plural",
    position: { x: -374, y: 0 },
    data: { label: " Множественного числа " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Verbs",
    position: { x: 0, y: 100 },
    data: { label: " Глаголы" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Verbs-action",
    position: { x: 200, y: 70 },
    data: { label: " Действия " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Verbs-linking",
    position: { x: 200, y: 120 },
    data: { label: " Связки " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Verbs-state",
    position: { x: 200, y: 170 },
    data: { label: " Состояния " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Verbs-regular",
    position: { x: 200, y: 220 },

    data: { label: " Правильные " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Verbs-irregular",
    position: { x: 375, y: 70 },
    data: { label: " Неправильные " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Verbs-present",
    position: { x: 375, y: 120 },
    data: { label: " Настоящее время" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  {
    id: "Verbs-past",
    position: { x: 375, y: 170 },
    data: { label: " Прошедшее время " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  {
    id: "Verbs-continuous",
    position: { x: 375, y: 220 },
    data: { label: " Длительное время " },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  {
    id: "Adjectives",
    position: { x: -0, y: 200 },
    data: { label: "Прилагательные" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Adjectives-descriptive",
    position: { x: -200, y: 180 },
    data: { label: "Описательные" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Adjectives-comprative",
    position: { x: -375, y: 180 },
    data: { label: "Сравнительная степень" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Adjectives-superlative",
    position: { x: -200, y: 230 },
    data: { label: "Превосходная степень" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  // adverbs
  {
    id: "Adverbs",
    position: { x: 0, y: 300 },
    data: { label: "Наречия" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  // Manner
  {
    id: "Adverbs-manner",
    position: { x: -370, y: 370 },
    data: { label: "Образа действия" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  // Frequency
  {
    id: "Adverbs-frequency",
    position: { x: -200, y: 370 },
    data: { label: "Частоты" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  // Time
  {
    id: "Adverbs-time",
    position: { x: -200, y: 320 },
    data: { label: "Времени" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  // Place
  {
    id: "Adverbs-place",
    position: { x: -370, y: 320 },
    data: { label: "Места" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  // Main Category Node
  {
    id: "Pronouns",
    position: { x: 0, y: 400 },
    data: { label: "Местоимения" }, // Pronouns
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  // Subcategory Nodes
  {
    id: "Pronouns-subject",
    position: { x: 200, y: 430 },
    data: { label: "Подлежащее (субъект)" }, // Subject
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #d4e6f3", // Replace with your preferred color
    },
  },
  {
    id: "Pronouns-object",
    position: { x: 375, y: 375 },
    data: { label: "Дополнение (объект)" }, // Object
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #d4e6f3", // Replace with your preferred color
    },
  },
  {
    id: "Pronouns-possessive",
    position: { x: 200, y: 320 },
    data: { label: "Притяжательные" }, // Possessive
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #d4e6f3", // Replace with your preferred color
    },
  },
  {
    id: "Pronouns-reflexive",
    position: { x: 200, y: 375 },
    data: { label: "Возвратные" }, // Reflexive
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #d4e6f3", // Replace with your preferred color
    },
  },
  {
    id: "Pronouns-demonstrative",
    position: { x: 375, y: 320 },
    data: { label: "Указательные" }, // Demonstrative
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #d4e6f3", // Replace with your preferred color
    },
  },

  //Preposition

  {
    id: "Prepositions",
    position: { x: 0, y: 500 },
    data: { label: "Предлоги" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },

  {
    id: "Preposition-time",
    position: { x: -370, y: 470 },
    data: { label: "Времени" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  {
    id: "Preposition-place",
    position: { x: -200, y: 470 },
    data: { label: "Места" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  {
    id: "Preposition-direction",
    position: { x: -200, y: 520 },
    data: { label: "Направления" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  {
    id: "Prepositions-connection",
    position: { x: -370, y: 520 },
    data: { label: "Связи" },
    style: {
      backgroundColor: "#93e9be", // Replace with your preferred color
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be", // Replace with your preferred color
    },
  },

  //Conjuctaions
  {
    id: "Conjunctions",
    position: { x: 0, y: 600 },
    data: { label: "Союзы" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  {
    id: "Conjunctions-coordinating",
    position: { x: 200, y: 540 },
    data: { label: "Соединительные" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  {
    id: "Conjunctions-subordinating",
    position: { x: 375, y: 540 },
    data: { label: "Подчинительные" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  {
    id: "Interjections",
    position: { x: 0, y: 690 },
    data: { label: "Междометия" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
  {
    id: "Interjections-expressofemotions",
    position: { x: -200, y: 670 },
    data: { label: "Выражения эмоций" },
    style: {
      backgroundColor: "#93e9be",
      fontWeight: "bold",
      padding: "7px",
      boxShadow: "0px 0px 20px 0px #93e9be",
    },
  },
];

const initialEdges = [
  // Nouns (common, proper, singular, plural)

  {
    id: "e1-1",
    source: "Partsofspeech",
    target: "Nouns",
    animated: true,
    style: { stroke: "#76DFB1", strokeWidth: 8, targetMarker: null },
  },
  {
    id: "e1-2",
    source: "Nouns",
    target: "Verbs",
    animated: true,
    style: { stroke: "#76DFB1", strokeWidth: 8, targetMarker: null },
  },
  {
    id: "e1-common",
    source: "Nouns",
    target: "Nouns-common",
    animated: true,
    style: { stroke: "#f47b24", strokeWidth: 2, targetMarker: null },
  },
  {
    id: "e1-proper",
    source: "Nouns",
    target: "Nouns-proper",
    animated: true,
    style: { stroke: "#f47b24", strokeWidth: 2, targetMarker: null },
  },

  {
    id: "e1-singular",
    source: "Nouns",
    target: "Nouns-singular",
    animated: true,
    style: { stroke: "#f47b24", strokeWidth: 2, targetMarker: null },
  },

  {
    id: "e1-plural",
    source: "Nouns",
    target: "Nouns-plural",
    animated: true,
    style: { stroke: "#f47b24", strokeWidth: 2, targetMarker: null },
  },

  {
    id: "e2-3",
    source: "Verbs",
    target: "Adjectives",
    animated: true,
    style: {
      stroke: "#76DFB1",
      strokeWidth: 8,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-action",
    source: "Verbs",
    target: "Verbs-action",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-linking",
    source: "Verbs-linking",
    target: "Verbs",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-state",
    source: "Verbs",
    target: "Verbs-state",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-regular",
    source: "Verbs",
    target: "Verbs-regular",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-irregular",
    source: "Verbs-irregular",
    target: "Verbs",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-present",
    source: "Verbs",
    target: "Verbs-present",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-past",
    source: "Verbs",
    target: "Verbs-past",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e2-continuous",
    source: "Verbs",
    target: "Verbs-continuous",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e3-4",
    source: "Adjectives",
    target: "Adverbs",
    animated: true,
    style: {
      stroke: "#76DFB1",
      strokeWidth: 8,
      targetMarker: { type: "arrow" },
    },
  },
  {
    id: "e3-comprative",
    source: "Adjectives",
    target: "Adjectives-comprative",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },
  {
    id: "e3-descriptive",
    source: "Adjectives",
    target: "Adjectives-descriptive",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e3-superlative",
    source: "Adjectives",
    target: "Adjectives-superlative",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e4-5",
    source: "Adverbs",
    target: "Pronouns",
    animated: true,
    style: {
      stroke: "#76DFB1",
      strokeWidth: 8,
      targetMarker: { type: "arrow" },
    },
  },
  // Edge connecting "Adverbs" to "Manner"
  {
    id: "e5-6",
    source: "Pronouns",
    target: "Articles",
    animated: true,
    style: {
      stroke: "#76DFB1",
      strokeWidth: 8,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e5-subject",
    source: "Pronouns",
    target: "Pronouns-subject",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },
  {
    id: "e5-object",
    source: "Pronouns",
    target: "Pronouns-object",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e5-possessive",
    source: "Pronouns",
    target: "Pronouns-possessive",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e5-reflexive",
    source: "Pronouns",
    target: "Pronouns-reflexive",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e5-demonstrative",
    source: "Pronouns",
    target: "Pronouns-demonstrative",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e6-manner",
    source: "Adverbs",
    target: "Adverbs-manner",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },

  // Edge connecting "Adverbs" to "Frequency"
  {
    id: "e6-frequency",
    source: "Adverbs",
    target: "Adverbs-frequency",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },

  // Edge connecting "Adverbs" to "Time"
  {
    id: "e6-time",
    source: "Adverbs",
    target: "Adverbs-time",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },

  // Edge connecting "Adverbs" to "Place"
  {
    id: "e6-place",
    source: "Adverbs",
    target: "Adverbs-place",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },
  {
    id: "e6-7",
    source: "Adverbs",
    target: "Prepositions",
    animated: true,
    style: {
      stroke: "#76DFB1",
      strokeWidth: 8,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e7-time",
    source: "Prepositions",
    target: "Preposition-time",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },

  // Edge connecting "Prepositions" to "Preposition-place"
  {
    id: "7-place",
    source: "Prepositions",
    target: "Preposition-place",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },

  // Edge connecting "Prepositions" to "Preposition-direction"
  {
    id: "e7-direction",
    source: "Prepositions",
    target: "Preposition-direction",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },

  // Edge connecting "Prepositions" to "Prepositions-connection"
  {
    id: "e7-connection",
    source: "Prepositions",
    target: "Prepositions-connection",
    animated: true,
    style: {
      stroke: "#f47b24", // Replace with your preferred color
      strokeWidth: 2,
      targetMarker: "arrowhead",
    },
  },

  {
    id: "e7-8",
    source: "Prepositions",
    target: "Conjunctions",
    animated: true,
    style: {
      stroke: "#76DFB1",
      strokeWidth: 8,
      targetMarker: { type: "arrow" },
    },
  },
  {
    id: "e8-e9",
    source: "Conjunctions",
    target: "Interjections",
    animated: true,
    style: {
      stroke: "#76DFB1",
      strokeWidth: 8,
      targetMarker: { type: "arrow" },
    },
  },
  {
    id: "e8-coordinationg",
    source: "Conjunctions",
    target: "Conjunctions-coordinating",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },
  {
    id: "e8-subordinationg",
    source: "Conjunctions",
    target: "Conjunctions-subordinating",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },

  {
    id: "e9-expressofemotions",
    source: "Interjections",
    target: "Interjections-expressofemotions",
    animated: true,
    style: {
      stroke: "#f47b24",
      strokeWidth: 2,
      targetMarker: { type: "arrow" },
    },
  },
];

const proOptions = { hideAttribution: true };

function Roadmap() {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <div style={{ width: "90vw", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        preventScrolling={false}
        panOnDrag={false}
        proOptions={proOptions}
      >
        <Controls position="top-left" />
        <MiniMap
          position="top-right"
          nodeColor={"green"}
          nodeStrokeWidth={3}
          zoomable
          pannable
        />
        <Background
          lineWidth={0.3}
          id="2"
          gap={50}
          color="#00FA9A"
          variant={BackgroundVariant.Lines}
        />
      </ReactFlow>
    </div>
  );
}

export default Roadmap;
